export default {
  nl: {
    coach: "Coach",
    greeting: "Dag",
    myProgram: "Mijn Programma",
    myProgramStopped: "Mijn Programma - Stopgezet",
    programType: "Type",
    programFormula: "Formule",
    programStartedOn: "Gestart op",
    programPhase: "Fase",
    hour: "uur",
    date: "Datum",
    numberOfHours: "Aantal uur",
    comment: "Opmerking",
    confirmedByYou: "Bevestigd door jou",
    youNotPresent: "Je was niet aanwezig",
    participantPresent: "Deelnemer aanwezig",
    yourCoach: "Jouw coach",
    confirmActivityButton: "Bevestig activiteit",
    notAssignedToProgram:
      "Je bent nog niet gekoppeld aan een programma. Later zal je hier meer informatie vinden over je programma.",
    explanationActivitiesParticipant:
      "Bekijk en bevestig hier je activiteiten voor deze fase.",
    activePrograms: "Actieve programmas",
    view: "Bekijk",
    program: "Programma",
    status: "Status",
    stop: "Onderbreken",
    restart: "Hervatten",
    participant: "Deelnemer",
    program_states: "Gebeurtenissen programma",
    edit: "Bewerken",
    signout: 'Afmelden',
    yes: 'Ja',
    no: 'Nee',
    activityConfirmed: 'Activiteit bevestigd',
    addActivity: 'Toevoegen',
    plannedStartDate: 'Geplande startdatum',
    active: 'Lopend',
    suspended: 'Onderbroken',
    archived: 'Gearchiveerd',
    noExtraInfo: 'Geen extra info',
    fullName: 'Volledige naam',
    created: 'Aangemaakt',
    resumed: 'Hervat',
    started: 'Gestart',
    completed: 'Afgerond',
    unknownStatus: 'Onbekende status',
    time: 'Tijdstip',
    details: 'Details',
    cancel: 'Annuleer',
    back: 'Terug',
    next: 'Volgende',
    confirm: 'Bevestig',
    numerOfHours: 'Aantal uur',
    numberOfMinutes: 'Aantal minuten',
    delete: 'Verwijderen',
    save: 'Opslaan',
    deleted: 'Verwijderd',
    archive: 'Archiveren',
    deArchive: 'Dearchiveren',
    on: 'Op',
    resume: 'Hervatten',
    emailOnActivityCreation: 'Email bij het aanmaken van een activiteit',
    accountSettings: 'Accountinstellingen',
    assignedContent: 'Toegewezen content',
    noContent: 'Geen content',
    assign: 'Toewijzen',
    content: 'Content',
    inscriptions: 'Inschrijvingen',
    meeting: 'Vergadering',
    assignments: 'Toewijzingen',
    noInscriptions: "Nog geen inschrijvingen",
    addParticipant: 'Deelnemer inschrijven',
    givenBy: 'Gegeven door',
    register: 'inschrijven',
    participantReigstered: 'Participant inscrit',
    communication: 'Communicatie',
    attachments: 'Bijlagen',
    send: 'Verzenden',
    message: 'Bericht',
    communicationSent: 'Communicatie verzonden',
    noActualActivitries: 'Geen activiteiten voor deze fase',
    participantAlreadyRegistered: 'Deze deelnemer is al ingeschreven',
    noWebinars: 'Geen ingeschreven webinars',
    addScreenCast: 'Voeg screencast toe',
    noScreenScasts: 'Geen screencasts toegevoegd',
    search: 'Zoeken',
    total: 'Totaal',
    progress: 'Voortgang',
    copyToClipboard: 'Kopieer naar klembord',
    copiedToClipboard: 'Gekopieerd naar klembord',
    webinarEnded: 'Webinar afgelopen',
    surveySent: 'Enquête verzonden',
    toConfirm: 'Te bevestigen',
    participantConfirmed: 'Deelnemer bevestigd',
  },
  fr: {
    coach: "Coach",
    yourCoach: "Votre coach",
    confirmActivityButton: "Confirmer",
    greeting: "Bonjour",
    myProgram: "Mon Programme",
    myProgramStopped: "Mon Programme - Arrêté",
    programType: "Type",
    programFormula: "Formule",
    programStartedOn: "Commencé le",
    programPhase: "Phase",
    hour: "heure",
    date: "Date",
    numberOfHours: "Nombre d'heures",
    comment: "Note",
    confirmedByYou: "Confirmer",
    youNotPresent: "Vous n'étiez pas présent",
    notAssignedToProgram: "Vous n'êtes pas encore lié à un programme.",
    explanationActivitiesParticipant:
      "Consultez et confirmez vos activités pour cette pahase ici.",
    activePrograms: "Programmes actifs",
    view: "Plus d'infos",
    program: "Programme",
    status: "Statut",
    stop: "Suspendre",
    restart: "Résumér",
    participant: "Participant",
    program_states: "Événements du programme",
    participantPresent: "Participant présent",
    edit: "Modifier",
    signout: 'Déconnecter',
    yes: 'Oui',
    no: 'Non',
    activityConfirmed: 'Activité confirmée',
    addActivity: 'Ajouter une activité',
    plannedStartDate: 'Date de début prévue',
    active: 'Actif',
    suspended: 'Interrompu',
    archived: 'Archivé',
    noExtraInfo: 'Pas d\'informations supplémentaires',
    fullName: 'Nom complet',
    created: 'Créé',
    resumed: 'Redémarré',
    started: 'Démarré',
    completed: 'Complété',
    unknownStatus: 'Statut inconnu',
    time: 'Temps',
    details: 'Details',
    cancel: 'Annuler',
    back: 'Retourner',
    next: 'continuer',
    confirm: 'Confirmer',
    numerOfHours: "Nombre d'heures",
    numberOfMinutes: 'Nombre de minutes',
    delete: 'Supprimer',
    save: 'Sauvez',
    deleted: 'Supprimé',
    archive: 'Archive',
    deArchive: 'Désarchivage',
    on: 'a',
    resume: 'redémarrer',
    emailOnActivityCreation: "Courriel lors de la création d'une activité",
    accountSettings: 'Paramètres du compte',
    assignedContent: 'Contenu attribué',
    noContent: 'Pas de contenu',
    assign: 'Attribuer',
    content: 'contenu',
    inscriptions: 'Entrées',
    meeting: 'réunion',
    assignments: 'Allocations',
    noInscriptions: "Pas encore d'inscriptions",
    addParticipant: 'Enregistrer un participant',
    givenBy: 'Donné par',
    register: 'registre',
    participantReigstered: 'Participant inscrit',
    communication: 'Communication',
    attachments: 'Pièces jointes',
    send: 'Envoyer',
    message: 'Message',
    communicationSent: 'Communication envoyée',
    noActualActivitries: 'Pas d\'activités pour cette phase',
    participantAlreadyRegistered: 'Ce participant est déjà inscrit',
    noWebinars: 'Pas de webinaires inscrits',
    addScreenCast: 'Ajouter un screencast',
    noScreenScasts: 'Aucun screencast ajouté',
    search: 'Chercher',
    total: 'Total',
    progress: 'Progression',
    copyToClipboard: 'Copier dans le presse-papiers',
    copiedToClipboard: 'Copié dans le presse-papiers',
    webinarEnded: 'Webinaire terminé',
    surveySent: 'Enquête envoyée',
    toConfirm: 'À confirmer',
    participantConfirmed: 'Participant confirmé',
  },
  en: {
    coach: "Coach",
  },
};
